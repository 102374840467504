<div class="mini-plant" [class.selected]="selected" *ngIf="plant">
  <div [style.background-image]="'url(' + plant.thumbnail + ')'" class="bgthumb"></div>
  <span class="text">
    <b>{{ plant.name }}</b>
    <div class="second-line">
      <app-plant-icon [type]="plant.plantType" size="small" class="top left"></app-plant-icon>
      <div>{{ plant.species }}</div>
    </div>
  </span>
  <div>
    <ng-content></ng-content>
  </div>
</div>
