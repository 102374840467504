import { Component, Input } from '@angular/core';
import { PlantType } from '../plants/types/plant-type';

export enum IconSize {
  small = 'small',
  mid = 'mid',
  large = 'large',
  xlarge = 'xlarge',
  xxlarge = 'xxlarge',
}

const EMPTY_ICON = null;

@Component({
  selector: 'app-plant-icon',
  templateUrl: './plant-icon.component.html',
  styleUrls: ['./plant-icon.component.scss'],
})
export class PlantIconComponent {
  _type: PlantType;
  icon: string;

  @Input() set type(type: PlantType) {
    this._type = type;
    this.icon = this.iconMap.get(this.type) || EMPTY_ICON;
  }
  get type(): PlantType {
    return this._type;
  }
  @Input() size = IconSize.mid;
  @Input() hasText = false;

  iconMap = new Map<PlantType, string>([
    [PlantType.Herb, 'Herb'],
    [PlantType.Perennial, 'Perennial'],
    [PlantType.Bush, 'Bush'],
    [PlantType.Tree, 'Tree'],
    [PlantType.Vine, 'Vine'],
    [PlantType.Unknown, 'Unknown'],
  ]);
}
