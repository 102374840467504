<footer>
  <div class="page-inner">
    <div class="row">
      <span>{{ 'FOOTER.APPNAME' | translate }} v{{ version }}</span>
      <span class="license">
        <mat-icon inline>copyright</mat-icon> {{curYear}} - {{ 'FOOTER.COPYRIGHT' | translate }}
      </span>
      <nav>
        <a routerLink="/misc/about">{{ 'PAGES.TITLES.ABOUT' | translate }}</a>
        <a routerLink="/misc/imprint">{{ 'FOOTER.IMPRINT' | translate }}</a>
        <a routerLink="/misc/privacy">{{ 'FOOTER.PRIVACY' | translate }}</a>
        <a routerLink="/misc/admin" *ngIf="isStaff$ | async">Admin</a>
      </nav>
    </div>
    <div class="supporters" *ngIf="showSupporters$ | async">
      <app-supporters [forFooter]="true" [hostname]="hostname$ | async"></app-supporters>
    </div>
  </div>
</footer>