import { Component, Input } from '@angular/core';
import { PlantSchemaLocalized } from 'src/app/modules/plants/types';

@Component({
  selector: 'app-mini-plant',
  templateUrl: './mini-plant.component.html',
  styleUrls: ['./mini-plant.component.scss']
})
export class MiniPlantComponent {
  @Input() plant: PlantSchemaLocalized;
  @Input() selected: boolean;
  constructor() { }
}
