import { createSelector } from '@ngrx/store';
import { State } from '../reducers';
import { CronState } from './cron.reducers';

export const cronStateSelector = (state: State) => state.cron;

export class CronSelectors {
  static daily = createSelector(cronStateSelector, (state: CronState) => state.daily);
  static minutely = createSelector(cronStateSelector, (state: CronState) => state.minutely);
}
