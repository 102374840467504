import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { PlantSuggestionSchema } from "src/app/modules/suggestions/types/suggestions";
import { ListResult } from "src/app/types/list-result";

export class SuggestionsActions {
  static fetchSuggestions = createAction('[Plants] fetch plants suggestions');
  static fetchSuggestionsSuccess = createAction(
    '[Plants] fetch plants suggestions success',
    props<{ result: ListResult<PlantSuggestionSchema> }>()
  );
  static fetchSuggestionsError = createAction(
    '[Plants] fetch plants suggestions error',
    props<{ error: HttpErrorResponse }>()
  );
  static createSuggestion = createAction(
    '[Plants] suggestion submitted',
    props<{ suggestion: PlantSuggestionSchema }>()
  );
  static createSuggestionOk = createAction(
    '[Plants] suggestion submitted success',
    props<{ suggestion: PlantSuggestionSchema }>()
  );
  static createSuggestionError = createAction(
    '[Plants] suggestion submitted error',
    props<{ error: any }>()
  );
  static deleteSuggestion = createAction(
    '[Plants] delete suggestion',
    props<{ id: number }>()
  );
  static deleteSuggestionOk = createAction(
    '[Plants] delete suggestion success',
    props<{ result: any }>()
  );
  static deleteSuggestionError = createAction(
    '[Plants] delete suggestion error',
    props<{ error: any }>()
  )
}
