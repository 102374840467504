import { Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertService } from './alert.service';
import { WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';
import { AppActions } from 'src/app/state/app/actions';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

/**
 * Communicate with the service worker and do updates / reloads etc.
 *
 * The idea is to put everything useful from
 * https://angular.io/guide/service-worker-communications
 * into this one service (unless there are good reasons to use separate services).
 */
@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  constructor(
    @Inject(WINDOW) private window,
    updates: SwUpdate,
    private alertService: AlertService,
    private store: Store,
    private translateService: TranslateService
  ) {
    console.log("Starting app version service")
    updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.store.dispatch(AppActions.newVersionAvailable({ newVersionAvailable: true }));
          this.triggerAlert();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });

    this.translateService.get(['APP.MESSAGES.ERROR', 'APP.MESSAGES.PLEASE_RELOAD']).pipe(take(1)).subscribe(translations => {
      updates?.unrecoverable?.subscribe(event => {
        alertService.openWarning(
          translations['APP.MESSAGES.ERROR'],
          event.reason + `\n\n${translations['APP.MESSAGES.PLEASE_RELOAD']}`,
          () => this.window.location.reload()
        );
      });
    })
  }

  triggerAlert() {
    this.translateService.get(['APP.MESSAGES.NEW_VERSION', 'APP.MESSAGES.PLEASE_UPDATE']).pipe(take(1)).subscribe(translations => {
      this.alertService.openWarning(
        translations['APP.MESSAGES.NEW_VERSION'],
        translations['APP.MESSAGES.PLEASE_UPDATE'],
        () => {
          this.store.dispatch(AppActions.newVersionAvailable({ newVersionAvailable: false }));
          this.window.location.reload();
        }
      );
    })
  }
}
