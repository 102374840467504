import { PlantReport } from '../types/plant-report';

/**
 * For a report , return count data as a string
 *
 * TODO: just a quick implementation, improve later
 * TODO: duplicated code ? - see src/app/modules/report/components/report-list-item/report-list-item.component.ts
 *
 * @param report
 */
export function reportCountData(report: PlantReport) {
  const fields = [
    'countHoneybees',
    'countBumblebees',
    'countWildbees',
    'countOther',
    'countHoverflies',
  ];

  return fields
    .map((field) => (report[field] ? field + ': ' + report[field] : ''))
    .filter((val) => !!val)
    .join(', ');
}


// export function validGeopos(item) {

// }