import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { ReportNowSelectors } from 'src/app/state/report/report-now.selectors';

@Component({
  selector: 'app-report-now',
  templateUrl: './report-now.component.html',
  styleUrls: ['./report-now.component.scss']
})
export class ReportNowComponent {
  plants$;
  constructor(private store: Store) {
    this.plants$ = this.store.select(ReportNowSelectors.rankedPlantlist).pipe(
      map(results => results.slice(0, 5))
    );
  }
}
