<div class="page-inner main">
  <h1>{{ 'ABOUT.HEADER' | translate }}</h1>
  <!-- <p>{{'ABOUT.TEXT' | translate}}</p> -->
  <section>
    <p>{{ 'ABOUT.PARAGRAPH1' | translate }}</p>
    <p>{{ 'ABOUT.PARAGRAPH2' | translate }}</p>
    <p>{{ 'ABOUT.PARAGRAPH3' | translate }}</p>
    <p>{{ 'ABOUT.PARAGRAPH4' | translate }}</p>
    <p>{{ 'ABOUT.PARAGRAPH5' | translate }}</p>
  </section>
  <section class="support">
    <!-- <div>
      <p>{{ 'ABOUT.SUPPORTED_BY' | translate }}</p>
      <p>
        <img width="480" class="about-logo" src="/assets/images/promilleafgiftsfonden-logo-480.png" />
      </p>
    </div> -->
    <p>&nbsp;</p>
    <div *ngIf="showSupporters$ | async">
      <p>{{ 'ABOUT.SUPPORTED_BY' | translate }}</p>
      <app-supporters [forFooter]="false" [hostname]="hostname$ | async"></app-supporters>
    </div>
    <p>&nbsp;</p>
    <div *ngIf="showSupporters$ | async">
      <p>Web Development</p>
      <a href="https://frey-it.com" target="blank">frey-it.com</a>
    </div>
  </section>
</div>