<div class="page-inner main">
    <div class="header-container hide-mobile">
        <h1>Admin</h1>
    </div>
    <div>
        <h2>Exports</h2>
        <ul>
        <li><a href="/api/staff/exports/users">User Data</a></li>
        <li><a href="/api/staff/exports/reports">Reporting Data</a></li>
        <li><a href="/api/staff/exports/suggestions">Suggestions</a></li>
        </ul>
    </div>
</div>