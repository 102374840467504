import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { GatrackingService } from 'src/app/modules/core/gatracking.service';
import { AppFeatures } from 'src/app/modules/core/types/app-features';
import { AppSelectors } from 'src/app/state/app/selectors';
import { AuthSelectors } from 'src/app/state/auth/auth.selectors';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent {
  authStatus$;
  appFeatures$: Observable<AppFeatures & { betterBIntro: boolean; betterBActive: boolean }>;
  titleImageClass$: Observable<string>;
  constructor(private store: Store, private ta: GatrackingService) {
    this.authStatus$ = this.store.select(AuthSelectors.authStatus);
    this.appFeatures$ = this.store.select(AppSelectors.appFeatures).pipe(
      map((features) => ({
        ...features,
        betterBIntro: features.siteSetting === 'betterb' && !features.loggedIn,
        betterBActive: features.siteSetting === 'betterb' && features.loggedIn,
      }))
    );

    // Show a different header image (via css class), depending on the modes settings
    this.titleImageClass$ = this.appFeatures$.pipe(
      map((appFeatures) => (appFeatures.siteSetting === 'betterb' ? 'title2' : 'title1'))
    );
  }
}
