import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
  paragraphs$;
  constructor(translateService: TranslateService) { 
    this.paragraphs$ = translateService.get('PRIVACY.TEXT').pipe(map(v => v.split("\n")));
  }
}
