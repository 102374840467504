import { Component, Input, OnInit } from '@angular/core';
import { getDayOfYear } from 'date-fns';
import { PlantBloomingPeriod } from '../../plants/types';
import { BloomingData } from '../../report/types/blooming-data';

@Component({
  selector: 'app-blooming',
  templateUrl: './blooming.component.html',
  styleUrls: ['./blooming.component.scss'],
})
export class BloomingComponent {
  _period: PlantBloomingPeriod;
  drawPeriods = [];
  drawPoints = [];
  drawRects = [];
  DIAGRAM_SIZE = 120;
  yStart = 3.5;
  yEnd = 16.5;
  boxY = 5;
  boxHeight = 10;
  textY = 25;
  monthTicks = [
    { x: 3, label: '1' },
    { x: 13, label: '2' },
    { x: 23, label: '3' },
    { x: 33, label: '4' },
    { x: 43, label: '5' },
    { x: 53, label: '6' },
    { x: 63, label: '7' },
    { x: 73, label: '8' },
    { x: 83, label: '9' },
    { x: 93, label: '10' },
    { x: 103, label: '11' },
    { x: 113, label: '12' },
  ];
  @Input() set period(period: PlantBloomingPeriod) {
    if (period) {
      this._period = period;
      const from = getDayOfYear(new Date(this._period.bloomingStartDate));
      const to = getDayOfYear(new Date(this._period.bloomingEndDate));

      const fact = this.DIAGRAM_SIZE / 365;
      if (isNaN(from) || isNaN(to)) {
        this.drawPeriods = [];
      } else if (from < to) {
        this.drawPeriods = [[from * fact, (to - from) * fact]];
      } else {
        this.drawPeriods = [
          [0, to * fact],
          [from * fact, this.DIAGRAM_SIZE],
        ];
      }
    } else {
      this.drawPeriods = [];
    }
  }
  get period() {
    return this._period;
  }

  @Input() set observations(observations: BloomingData[]) {
    if (observations) {
      // Draw Blooming status 1 - 4 as circles
      this.drawPoints = observations
        .filter((obsv) => obsv.bloomingStatus !== 5)
        .map((obsv) => {
          const day = getDayOfYear(new Date(obsv.reportDate));
          const fact = this.DIAGRAM_SIZE / 365;
          return {
            x: day * fact,
            y: 9.5, // + obsv.bloomingStatus * 1.5,
            class: 'bl-st-' + obsv.bloomingStatus,
          };
        });
    }
  }

  constructor() { }
}
