import { createReducer, on } from '@ngrx/store';
import { CronActions } from './cron.actions';

export interface CronState {
  lastRun: string | null;
  daily: string | null;
  hourly: string | null;
  minutely: string | null;
}

const initialState =
{
  lastRun: null,
  daily: null,
  hourly: null,
  minutely: null,
};

export const cronReducer = createReducer<CronState>(
  initialState,

  on(CronActions.daily, (state, { ts }) => ({
    ...state,
    daily: ts
  })),

  // Uncomment for minutely cron signal
  // on(CronActions.minutely, (state, { ts }) => ({
  //   ...state,
  //   minutely: ts
  // })),

);

