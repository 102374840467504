import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GreeterSelectors } from 'src/app/state/greeter/greeter.selectors';

/**
 * The so-called "greeter" component displays notifications for plants that
 * are currently requested for reporting - to "drive engagement".
 *
 * While there could be other action targets ("see current reports", "see reporting
 * hall of fame"), the primary action target is to link to a plant (or even directly
 * to the eporting of the plant).
 *
 * NOTE: this is mainly the "cards-slider" and container component:
 * - Message building see greeter.selectors.
 * - Message templte see greeter-card.component.
 *
 */
@Component({
  selector: 'app-greeter',
  templateUrl: './greeter.component.html',
  styleUrls: ['./greeter.component.scss']
})
export class GreeterComponent implements OnDestroy{
  messages$;
  reports$;
  index = 0;
  max = 0;
  maxValSub: Subscription;
  constructor(store: Store) {
    this.messages$ = store.select(GreeterSelectors.messages);
    this.maxValSub = this.messages$.subscribe(value => {
      this.max = value.length;
    });
  }

  ngOnDestroy(): void {
      if (this.maxValSub) {
        this.maxValSub.unsubscribe();
      }
  }

  next() {
    this.index = Math.min(this.index + 1, this.max - 1);
  }

  prev() {
    this.index = Math.max(this.index - 1, 0);
  }

}
