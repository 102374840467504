<div id="styleguide">

  <section data-name="bar-content">
    <div class="app-bar-content size-small with-topbar">
      <h3 class="mat-h1">Highlighted Info</h3>
      <p>
        Some highlighted paragraph with a short,
        striking header. The paragraph should really
        contains some good information, to make
        the reader's investment of attention truly worthwhile !
      </p>
    </div>
    <div class="app-bar-content with-topbar">
      <h3 class="mat-h1">Explore our tools</h3>
      <div class="boxes">
        <div>
          <mat-icon>add</mat-icon>
          <h3 class="mat-h2">Blooming Maps</h3>
          <p>
            Explore our blooming maps by country and region.
          </p>
        </div>
        <div>
          <mat-icon>home</mat-icon>
          <h3 class="mat-h2">Blooming Periods</h3>
          <p>
            Blooming periods for the most important beeplants, organized by year and region.
          </p>
        </div>
        <div>
          <mat-icon>favorite</mat-icon>
          <h3 class="mat-h2">Reporting Lists</h3>
          <p>
            Current most reported plants, with up-to-the-minute data.
          </p>
        </div>
      </div>
    </div>
    <div class="app-bar-content alert with-topbar">
      <div class="boxes">
        <div class="alert">
          <mat-icon>error</mat-icon>
          <h3 class="mat-h2">Header</h3>
          <p>
            This is more like a heads-up / warning now.
          </p>
        </div>
      </div>
    </div>
    <div class="app-bar-content alert">
      <div class="boxes">
        <div class="alert noheader">
          <mat-icon class="alert">error</mat-icon>
          <p>
            No topbar but stronger alert icon
          </p>
        </div>
      </div>
    </div>
  </section>

  <section data-name="map-styles">
    <app-simple-map [points]="[
      {longitude: 15.43, latitude: 47.06, popup: 'Hello'},
      {longitude: 15.432, latitude: 47.071},
      {longitude: 15.41, latitude: 47.071},
      {},
      ]"></app-simple-map>
  </section>
  <section data-name="component-styles">
    <div>
      <span class="app-simple-chip">
        <mat-icon inline>check</mat-icon>Apples
      </span>
      <span class="app-simple-chip">
        Oranges
      </span>
      <span class="app-simple-chip">
        Pears
      </span>
    </div>
  </section>

  <section data-name="gradient-image-backgroundf">
    <div class="gradient-image">
      <h1>Hello this</h1>
      <h2>is overlayed</h2>
      <p>
        Some paragraph, some content.
        The text is written on dark background, an image
        is blended in with the background, clearly visible at
        first, then going darker until it fades into the
        background color.
      </p>
      <mat-divider></mat-divider>
      <p>
        Some more content, another paragraph etc.
      </p>
    </div>
  </section>

  <section data-name="typography-material">
    <div *ngFor="let cls of typography" class="{{cls}}">{{cls}}</div>
  </section>

  <section data-name="typography">
    <article>
      <h1>Headline1</h1>
      <h2>Headline2</h2>
      <h3>Headline3</h3>
      <h4>Headline4</h4>
      <p>Almost before we knew it, we had left the ground.</p>
      <p>
        A Tong can perhaps be defined as a mutual benefit society for people with a common interest which is illegal or
        dangerously marginal—hence, the necessary secrecy.
      </p>
      <p>
        If a <a href="https://disktree.net">Tong</a> is organized around a special interest (especially an illegal or
        risky or marginal interest) it
        certainly has the right to compose itself according to the “affinity group” principle. If secrecy means (a)
        avoiding publicity & (b) vetting possible members, the “secret society” can scarcely be accused of violating
        anarchist principles. In fact, such societies have a long & honorable history in the anti-authoritarian movement
        …
      </p>
      <p>
        Many non-authoritarian organizations have foundered on the dubious principle of open membership, which
        frequently
        leads to a preponderance of assholes, yahoos, spoilers, whining neurotics, & police agents. Some will call this
        an
        elitist attitude, but it is not—at least not in the C. Wright Mills sense of the word: that is, a small group
        which exercises power over non-insiders for its own aggrandizement. Immediatism does not concern itself with
        power-relations;—it desires neither to be ruled nor to rule. The contemporary Tong therefore finds no pleasure
        in
        the degeneration of institutions into conspiracies. It wants power for its own purposes of mutuality. It is a
        free
        association of individuals who have chosen each other as the subjects of the group’s generosity, its
        “expansiveness” (to use a sufi term). If this amounts to some kind of “elitism,” then so be it.
      </p>
      <caption>NEKTAR</caption>
      <caption>POLLEN</caption>
    </article>
  </section>

  <section data-name="form">
    <mat-form-field class="fullsize">
      <mat-label>This is a fullsize form field</mat-label>
      <input matInput type="text">
    </mat-form-field>
  </section>

  <section data-name="colors">
    <div class="primary-color">Primary</div>
    <div class="accent-color">Accent</div>
    <div class="primary-color-inverse">Primary Inverse</div>
    <div class="accent-color-inverse">Accent Inverse</div>
  </section>

  <section data-name="favicons">
    <div>
      <img src="/favicon.svg">
      <img src="/favicon.ico">
    </div>
  </section>

  <section data-name="icons">
    <div>
      <mat-icon>arrow_back</mat-icon>
      <mat-icon>arrow_forward</mat-icon>
      <mat-icon>add</mat-icon>
      <mat-icon>check</mat-icon>
      <mat-icon>chevron_left</mat-icon>
      <mat-icon>chevron_right</mat-icon>
      <mat-icon>close</mat-icon>
      <mat-icon>delete</mat-icon>
      <mat-icon>edit</mat-icon>
      <mat-icon>error_outline</mat-icon>
      <mat-icon>favorite</mat-icon>
      <mat-icon>home</mat-icon>
      <mat-icon>list</mat-icon>
      <mat-icon>menu</mat-icon>
      <mat-icon>save</mat-icon>
      <mat-icon>remove</mat-icon>
      <mat-icon>refresh</mat-icon>
      <mat-icon>turned_in</mat-icon>
    </div>
  </section>

  <section data-name="components">
    <h3>app-blooming</h3>
    <app-blooming [period]="{bloomingStartDate: '2023-05-17', bloomingEndDate: '2023-08-4'}" [observations]="[
        {reportDate: '2023-06-15', bloomingStatus: 3},
        {reportDate: '2023-07-02', bloomingStatus: 3},
        {reportDate: '2023-09-08', bloomingStatus: 3},
      ]"></app-blooming>

  </section>

  <section data-name="material-buttons">
    <div class="example-button-row">
      <button mat-button>Basic</button>
      <button mat-button color="primary">Primary</button>
      <button mat-button color="accent">Accent</button>
      <button mat-button color="warn">Warn</button>
      <button mat-button disabled>Disabled</button>
      <a mat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
    <div>
      <div class="example-button-row">
        <button mat-raised-button matTooltip="Info about the action">Basic</button>
        <button mat-raised-button color="primary">Primary</button>
        <button mat-raised-button color="accent">Accent</button>
        <button mat-raised-button color="warn">Warn</button>
        <button mat-raised-button disabled>Disabled</button>
        <a mat-raised-button href="https://www.google.com/" target="_blank">Link</a>
      </div>
    </div>
    <div>
      <div class="example-button-row">
        <button mat-stroked-button>Basic</button>
        <button mat-stroked-button color="primary">Primary</button>
        <button mat-stroked-button color="accent">Accent</button>
        <button mat-stroked-button color="warn">Warn</button>
        <button mat-stroked-button disabled>Disabled</button>
        <a mat-stroked-button href="https://www.google.com/" target="_blank">Link</a>
      </div>
    </div>
  </section>

  <section data-name="svg">
    <svg viewBox="0 0 24 24" height="24" width="24" style="visibility: hidden">
      <style>
        circle,
        rect {
          fill: var(--color-primary);
          stroke-width: 2;
        }
      </style>
      <g id="icon-tree">
        <circle cx="12" cy="9" r="7" />
        <rect width="2" height="10" x="11" y="12" />
        <rect width="10" height="2" x="7" y="20" />
      </g>
    </svg>
    <div>
      <svg viewBox="0 0 24 24" width="48" height="48">
        <use href="#icon-tree" />
      </svg>
      <svg viewBox="0 0 24 24" width="48" height="48">
        <use href="#icon-tree" />
      </svg>
    </div>
  </section>

  <!--
  <section data-name="animations">
    <div data-animation="pulse" class="animation-test pulse"></div>
    <div data-animation="tada" class="animation-test tada"></div>
    <div data-animation="rotate_in" class="animation-test rotate_in"></div>
  </section>
  -->

  <section data-name="actions">
    <a mat-button (click)="showAlert()">Show Alert</a>
    <a mat-button (click)="produceAnError()">Produce an Error (test error handling)</a>
  </section>

</div>