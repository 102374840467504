import { createAction, props } from '@ngrx/store';
import { PlantReport } from '../../modules/report/types/plant-report';
import { ReportSpot } from 'src/app/modules/report/types/report-spot';

export class ReportActions {
  static testAction = createAction('[Report] Just A Test Actions', props<{ something: any }>());

  static reportSubmitted = createAction(
    '[report] report submit request',
    props<{ report: PlantReport }>()
  );
  static reportSubmitOk = createAction('[report] report submit success', props<{ data: any }>());
  static reportSubmitError = createAction('[report] report submit failed', props<{ error: any }>());

  // Get reports
  // NOTE / TODO: look into caching the data - see plant/catalogue store slice
  // static fetchReportsCache = createAction('[reports] Check Cache');
  // static fetchReportsCacheHit = createAction('[reports] Fetch From Cache');
  static fetchReports = createAction('[report] fetch');
  static getReportsSucess = createAction(
    '[report] get reports success',
    props<{ reports: PlantReport[] }>()
  );
  static getReportsError = createAction('[report] get reports failed', props<{ error: any }>());

  // delete report
  static deleteReportRequest = createAction(
    '[report] delete report request',
    props<{ id: number }>()
  );
  static deleteReportSucess = createAction(
    '[report] delete report success',
    props<{ result: any }>()
  );
  static deleteReportError = createAction('[report] delete report failed', props<{ error: any }>());

  //
  // Get spots
  // NOTE / TODO: look into caching the data - see plant/catalogue store slice
  //
  static fetchSpots = createAction('[spot] fetch');
  static fetchSpotsSuccess = createAction('[spot] fetch sucess', props<{ spots: ReportSpot[] }>());
  static fetchSpotsError = createAction('[spot] fetch failed', props<{ error: any }>());

  static fetchSpotsCache = createAction('[spot] Check Cache');
  static fetchSpotsCacheHit = createAction('[spot] Fetch From Cache');

  static selectSpot = createAction('[spot] select spot', props<{ spot: ReportSpot }>());
}
