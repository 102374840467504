import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerRequestAction } from '@ngrx/router-store';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CronSelectors } from './cron.selectors';
import { Store } from '@ngrx/store';
import { format } from "date-fns";
import { CronActions } from './cron.actions';

const DAILY_FMT_STR = 'yyyy-MM-dd';
const MINUTELY_FMT_STR = 'yyyy-MM-dd HH:mm';

@Injectable()
export class CronEffects {
  /**
   * On every navigation step, update the "cron" state.
   * That means "cron" events will happen (or might happen) whenever navigation is performed.
   *
   */
  onNavigation$ = createEffect(() =>
    this.actions.pipe(
      ofType(routerRequestAction),
      withLatestFrom(this.store.select(CronSelectors.daily), this.store.select(CronSelectors.minutely)),
      switchMap(([action, currentDaily, currentMinutely]) => {
        const actions = [];
        const currentDate = new Date();
        actions.push(CronActions.noOp());

        if (currentDaily === null || format(currentDate, DAILY_FMT_STR) !== currentDaily) {
            actions.push(CronActions.daily({ts: format(currentDate, DAILY_FMT_STR)}));
        }

        // Unccomment for minutely cron signals
        // if (currentMinutely === null || format(currentDate, MINUTELY_FMT_STR) !== currentMinutely) {
        //     actions.push(CronActions.minutely({ts: format(currentDate, MINUTELY_FMT_STR)}));
        // }
        return actions;
      })
    )
  );

  constructor(
    private store: Store,
    private actions: Actions,
  ) {}
}
