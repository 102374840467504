import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { AuthSelectors } from 'src/app/state/auth/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class CanActivateAuth implements CanActivate {
  constructor(private store: Store, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.store.select(AuthSelectors.loggedInOrError).pipe(
      retry({count: 8, delay: 1000 }),
      map(status => status || this.router.parseUrl('/user/login')),
      catchError(err => of(this.router.parseUrl('/user/login')))
    );
  }
}

