import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiscRoutingModule } from './misc-routing.module';
import { WelcomePageComponent } from './page/welcome/welcome-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StyleguideComponent } from './page/styleguide/styleguide.component';
import { SharedModule } from '../shared/shared.module';
import { AboutPageComponent } from './page/about/about-page.component';
import { ImprintPageComponent } from './page/imprint/imprint-page.component';
import { LocationPageComponent } from './page/location/location-page.component';
import { MapsModule } from '../maps/maps.module';
import { GreeterComponent } from './components/greeter/greeter.component';
import { GreeterCardComponent } from './components/greeter-card/greeter-card.component';
import { PrivacyComponent } from './page/privacy/privacy.component';
import { GeopositionHelpComponent } from './page/geoposition-help/geoposition-help.component';
import { DownloadsComponent } from './page/downloads/downloads.component';
import { DocsComponent } from './page/docs/docs.component';
import { MarkdownModule } from 'ngx-markdown';
import { AdminComponent } from './page/admin/admin.component';

@NgModule({
  declarations: [
    AboutPageComponent,
    WelcomePageComponent,
    StyleguideComponent,
    ImprintPageComponent,
    LocationPageComponent,
    GreeterComponent,
    GreeterCardComponent,
    PrivacyComponent,
    GeopositionHelpComponent,
    DownloadsComponent,
    DocsComponent,
    AdminComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MiscRoutingModule,
    MapsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({ extend: true }),
    MarkdownModule.forChild(),
  ],
})
export class MiscModule {}
