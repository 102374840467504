import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { SuggestionsActions } from "./actions";
import { catchError, exhaustMap, map, of, tap } from "rxjs";
import { PlantsSuggestionsService } from "src/app/modules/suggestions/services/suggestions.service";
import { MessageService } from "src/app/modules/core/message.service";

@Injectable()
export class SuggestionsEffects {
  fetchSuggestions$ = createEffect(() =>
    this.actions.pipe(
      ofType(SuggestionsActions.fetchSuggestions),
      exhaustMap(() =>
        this.suggestionsService.getSuggestions().pipe(
          map(result => SuggestionsActions.fetchSuggestionsSuccess({ result })),
          catchError(error => of(SuggestionsActions.fetchSuggestionsError({ error })))
        )
      )
    )
  );

  suggestionSubmitted$ = createEffect(() =>
    this.actions.pipe(
      ofType(SuggestionsActions.createSuggestion),
      exhaustMap(({ suggestion }) =>
        this.suggestionsService.addSuggestion(suggestion).pipe(
          map(result => SuggestionsActions.createSuggestionOk({ suggestion: result })),
          catchError(error => of(SuggestionsActions.createSuggestionError({ error })))
        )
      )
    )
  );

  suggestionSubmittedOk$ = createEffect(() =>
    this.actions.pipe(
      ofType(SuggestionsActions.createSuggestionOk),
      tap(() => {
        this.message.message('SUGGESTIONS.MESSAGES.CREATE_SUCCESS');
      }),
      map(() => SuggestionsActions.fetchSuggestions())
    )
  );

  suggestionSubmittedError$ = createEffect(() =>
    this.actions.pipe(
      ofType(SuggestionsActions.createSuggestionError),
      tap(() => this.message.message('SUGGESTIONS.MESSAGES.CREATE_ERROR')),
    ), { dispatch: false }
  );

  deleteSuggestion$ = createEffect(() =>
    this.actions.pipe(
      ofType(SuggestionsActions.deleteSuggestion),
      exhaustMap(({ id }) =>
        this.suggestionsService.deleteSuggestion(id).pipe(
          map(result => SuggestionsActions.deleteSuggestionOk({ result })),
          catchError(error => of(SuggestionsActions.deleteSuggestionError({ error })))
        )
      )
    )
  );

  deleteSuggestionOk$ = createEffect(() =>
    this.actions.pipe(
      ofType(SuggestionsActions.deleteSuggestionOk),
      tap(() => this.message.message('SUGGESTIONS.MESSAGES.DELETE_SUCCESS')),
      map(() => SuggestionsActions.fetchSuggestions())
    )
  );

  deleteSuggestionError$ = createEffect(() =>
    this.actions.pipe(
      ofType(SuggestionsActions.deleteSuggestionError),
      tap(() => this.message.message('SUGGESTIONS.MESSAGES.DELETE_ERROR'))
    ), { dispatch: false }
  );

  constructor(
    private actions: Actions,
    private suggestionsService: PlantsSuggestionsService,
    private message: MessageService
  ) { }
}
