<div class="container">
  <div
    *ngFor="let message of messages$ | async; let i = index"
    class="greeter-card"
    [ngClass]="{ active: i === index, past: i < index, next: i > index }"
    [style.background-image]="message.bg ? 'url(' + message.bg + ')' : ''"
  >
    <app-greeter-card [message]="message"></app-greeter-card>
  </div>
</div>
<div class="buttons">
  <button mat-raised-button (click)="prev()" [style.visibility]="index <= 0 ? 'hidden' : ''">
    <mat-icon>navigate_before</mat-icon>
  </button>
  <button mat-raised-button (click)="next()" [style.visibility]="index >= max - 1 ? 'hidden' : ''">
    <mat-icon>navigate_next</mat-icon>
  </button>
</div>