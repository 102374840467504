<ng-container *ngIf="appFeatures$ | async as appFeatures">
<mat-toolbar>
  <div class="page-inner">
    <div class="biavl-logo" [class.hide]="searchActive">
      <a routerLink="/" (click)="navigateAction()" class="logo" alt="Biavl.dk Logo">
        <img src="assets/images/biavl-logo-blue-small.png" height="35" width="40" />
      </a>
    </div>
    <app-toolbar-title
      class="title"
      [class.hide]="searchActive"
      (titleClick)="titleClicked()"
      (navigated)="navigateAction()"
    ></app-toolbar-title>

    <app-menu class="menu" [class.hide]="searchActive" (expandChange)="setExpand($event)" [features]="appFeatures"></app-menu>

    <!-- Global Search -->
    <div class="search" [class.active]="searchActive">
      <app-global-search (searchChanged)="searchChanged($event)" (searchActive)="this.searchActive = $event" [active]="searchActive"/>
    </div>

    <!-- User / Sign-Up -->
    <div class="user expand-container" [class.hide]="searchActive">
      <ng-container *ngIf="user$ | async as user; else noUser">
        <a routerLink="/user/user" (click)="navigateAction()"
          ><mat-icon inline>person</mat-icon>{{ user?.username }}</a
        >
      </ng-container>
      <ng-template #noUser>
        <a routerLink="/user/login" (click)="navigateAction()" mat-button>{{
          'USER.BUTTONS.LOGIN' | translate
        }}</a>
        <a routerLink="/user/signup" (click)="navigateAction()" mat-raised-button color="accent">{{
          'USER.PAGES.SIGNUP' | translate
        }}</a>
      </ng-template>
    </div>

    <!-- Burger Dropdown -->
    <div class="expand-container" [class.hide]="searchActive">
      <a class="expand-symbol" (click)="toggle()">
        <mat-icon>{{ expanded ? 'close' : 'menu' }}</mat-icon>
      </a>
    </div>

  </div>
</mat-toolbar>

<div class="toolbar-spacer"></div>

<div class="panel-container" [class.expanded]="expanded">
  <app-menu-panel-container>
    <app-menu-panel
      *ngIf="search?.length <= 0 || !searchActive else searchMenu"
      (routeClick)="navigateAction()"
      [user]="user$ | async"
      [features]="appFeatures"
    ></app-menu-panel>
  </app-menu-panel-container>

</div>
<div class="overlay" [class.expanded]="expanded" (click)="toggle()"></div>

<!-- <app-offline-notifier></app-offline-notifier> -->

<!--TODO maybe this can be one component too-->
<ng-template #searchMenu>
  <div class="filtered-plants-container" *ngIf="filteredPlants$ | async as plants">
    <app-mini-plant *ngFor="let plant of plants" [plant]="plant" [routerLink]="['/plants', plant.id]" (click)="navigateAction()"></app-mini-plant>
  </div>
</ng-template>
</ng-container>