import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { map } from "rxjs";
import { AuthSelectors } from "src/app/state/auth/auth.selectors";

// NOTE: this cannot resolve when accessing directly
// the user is defined only after the effect that fetches user status has run
// Maybe some rxjs retry logic  would help mitigate this ...
export  const canActivateStaff: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(Store).select(AuthSelectors.user).pipe(map(user => !!(user && user.is_staff)));
};