import { Component } from '@angular/core';

@Component({
  selector: 'app-menu-panel-container',
  templateUrl: './menu-panel-container.component.html',
  styleUrls: ['./menu-panel-container.component.scss']
})
export class MenuPanelContainerComponent {

}
