import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppActions } from 'src/app/state/app/actions';
import { AppSelectors } from 'src/app/state/app/selectors';

@Component({
  selector: 'app-geoposition-help',
  templateUrl: './geoposition-help.component.html',
  styleUrls: ['./geoposition-help.component.scss']
})
export class GeopositionHelpComponent  {
  geopos$;
  geoposStatus$;
  constructor(private store: Store) {
    this.geopos$ = this.store.select(AppSelectors.improvedGeopos);
    this.geoposStatus$ = this.store.select(AppSelectors.geoposStatus);
  }

  requestLocation() {
    this.store.dispatch(AppActions.requestGeoPos());
  }

}
