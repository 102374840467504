import { createSelector } from '@ngrx/store';
import { State } from '../reducers';
import { NavigationStack, NavigationState } from './reducers';
import { AppSelectors } from '../app/selectors';

export const navigationStateSelector = (state: State) => state.navigation;

export class NavigationSelectors {
  static navigationStack = createSelector(
    navigationStateSelector,
    (state: NavigationState) => state.navigationStack,
  );

  static previousNavigation = createSelector(
    NavigationSelectors.navigationStack,
    (navStack) => navStack[navStack.length - 2]
  );

  static currentNavigationRaw = createSelector(
    NavigationSelectors.navigationStack,
    (navStack) => navStack[navStack.length - 1]
  );

  static currentNavigation = createSelector(
    NavigationSelectors.currentNavigationRaw,
    AppSelectors.settingByHost,
    (navStack: NavigationStack, setting: 'betterb' | 'pollen') => ({
      ...navStack,
      // Override the MAIN title, depending on the site config
      title: navStack.defaultStack ? (setting === 'betterb' ? 'PAGES.TITLES.MAIN_BEEPLANTSEU' : navStack.title) : navStack.title
    })
  );

  static currentState = createSelector(
    NavigationSelectors.previousNavigation,
    NavigationSelectors.currentNavigation,
    (prev, cur) => ({
      current: cur,
      previous: prev
    })
  );

  static getPlantId = createSelector(
    navigationStateSelector,
    (state: NavigationState) => state.plantId
  );
}
