import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent {
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchActive: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() set active(val: boolean) {
    if (val) {
      this.openSearch();
    } else {
      this.closeSearch();
    }
  }

  search = new FormControl('');
  searchIsVisible = false;

  constructor() {
    this.search.valueChanges.pipe(debounceTime(250)).subscribe(value => {
      this.searchChanged.emit(value)
    });
  }

  toggleSearch(): void {
    this.searchIsVisible ? this.closeSearch() : this.openSearch();
  }

  openSearch(): void {
    this.searchIsVisible = true;
    this.searchActive.emit(true);
    setTimeout(() => this.searchInput.nativeElement.focus(), 50);
  }

  closeSearch(): void {
    this.searchIsVisible = false;
    this.search.setValue(null);
    this.searchActive.emit(false);
  }

  searchKeydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.closeSearch();
    }
  }
}
