import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { select } from '@ngrx/store';

import * as L from 'leaflet';

const LOW_MAX_ZOOM = 12;
const HIGH_MAX_ZOOM = 18;
const MARKER_DOT_STYLE = {
  color: 'red',
  fillColor: 'red',
};
const DEFAULT_MARKER_STYLE = {
  color: 'red',
  fillColor: '#8a3',
  fillOpacity: 0.75,
  radius: 6.0,
  // border width
  weight: 0.5,
};

export interface SimpleMapPoint {
  longitude: number;
  latitude: number;
  popup?: any;
  borderColor?: string;
  fillColor?: string;
}
@Component({
  selector: 'app-simple-map',
  templateUrl: './simple-map.component.html',
  styleUrls: ['./simple-map.component.scss'],
})
export class SimpleMapComponent implements AfterViewInit, OnDestroy {
  @Input() mapId = 'map1';

  /**
   * Whether to change zoom / boundaries when points change.
   */
  @Input() autoZoom = true;

  @Input() set fullsize(val: boolean) {
    this._fullsize = val;

    // https://stackoverflow.com/questions/24412325/resizing-a-leaflet-map-on-container-resize
    if (this.map) {
      setTimeout(() => this.map.invalidateSize(), 250);
    }
  }
  get fullsize(): boolean {
    return this._fullsize;
  }

  @Input() set allowHighZoom(val: boolean) {
    this._maxZoom = val ? HIGH_MAX_ZOOM : LOW_MAX_ZOOM;
    if (this.map) {
      this.map.options.maxZoom = this._maxZoom;
    }
  }
  get allowHighZoom() {
    return this._maxZoom > LOW_MAX_ZOOM;
  }

  map;

  _points;
  _pointsToAddWhenMapReady;
  _fullsize = false;
  _added = [];
  _selectedMarker;
  _maxZoom = LOW_MAX_ZOOM;

  @Input() set points(points: Array<SimpleMapPoint>) {
    // Keep the points that will be added when map is ready
    // this mitigates the issue that the map might not be ready at the time the points
    // are passed in.
    this._pointsToAddWhenMapReady = points
      // Map the coordinates to string and parse as float
      // This way it's either NaN or a valid float
      .map((point) => ({
        ...point,
        latitude: parseFloat('' + point.latitude),
        longitude: parseFloat('' + point.longitude),
      }))
      // Filter out all points without valid coordinates
      .filter((point) => !isNaN(point.latitude) && !isNaN(point.longitude));

    if (this.map) {
      // Add points to map
      this.addPoints();
    }
  }

  /**
   * Input to select a point from "outside" / the parent component.
   */
  @Input()
  set selectedPoint(selectedPoint: { longitude: number; latitude: number }) {
    this._selectedPoint = selectedPoint;
    if (this.map) {
      this.selectPoint(selectedPoint.latitude, selectedPoint.longitude, false);
    }
  }
  get selectedPoint() {
    return this._selectedPoint;
  }
  private _selectedPoint: SimpleMapPoint;
  @Output() selectedPointChange = new EventEmitter<SimpleMapPoint>();

  ngAfterViewInit() {
    if (!this.map) {
      this.initMap();

      // Add points to map, if there are any
      this.addPoints();
    }
  }

  /**
   * Update points of map.
   */
  private addPoints() {
    // Start by removing previously added points
    this._added.forEach((item) => item.remove());

    // Add points
    this._points = this._pointsToAddWhenMapReady || [];
    this._pointsToAddWhenMapReady = []; // Reset this
    this._added = this._points.map((point) => {
      const circle = L.circleMarker(
        [point.latitude, point.longitude],
        this.getDefaultMarkerStyle(point)
      )
        .addTo(this.map)
        .on('click', (event) => {
          const latlng = event.sourceTarget._latlng;
          this._selectedPoint = this._points.find(
            (point) => latlng.lat === point.latitude && latlng.lng === point.longitude
          );
          this.selectPoint(latlng.lat, latlng.lng, true);
        });

      if (point.popup) {
        circle.bindPopup(point.popup);
      }

      return circle;
    });

    if (this.autoZoom) {
      this.doAutoZoom();
    }
  }

  private initMap() {
    this.map = L.map(this.mapId, {
      minZoom: 2,
      maxZoom: this._maxZoom,
      center: [55.676098, 12.568337],
      zoom: 5,
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
  }

  /**
   * Use fit to Bounds to set the map view and zoom level.
   */
  private doAutoZoom() {
    // Get all point coordinates
    const longs = this._points.map((p) => parseFloat(p.longitude)).filter((p) => !isNaN(p));
    const lats = this._points.map((p) => parseFloat(p.latitude)).filter((p) => !isNaN(p));

    if (longs.length === 0 || lats.length === 0) {
      return;
    }

    if (longs.length) {
      // fitToBounds options
      // Prevent zooming in too much / at all, by limiting to
      // current zoom level
      const fitOptions = {};
      const currentZoom = this.map.getZoom();
      if (currentZoom) {
        fitOptions['maxZoom'] = currentZoom;
      }

      // https://leafletjs.com/reference.html#map-fitbounds
      this.map.fitBounds(
        [
          [Math.min(...lats), Math.min(...longs)],
          [Math.max(...lats), Math.max(...longs)],
        ],
        fitOptions
      );
    }
  }

  private selectPoint(lat: number, lng: number, emit = true): void {
    // console.log("selecting", lat, lng, emit);

    //Reset the current selected marker style
    if (this._selectedMarker && this._selectedPoint) {
      this._selectedMarker?.setStyle(this.getDefaultMarkerStyle(this._selectedPoint));
      this.map.closePopup();
    }

    // Get the new selected marker on the map
    const newSelectedMarker = this._added.find(
      (marker) => lat === marker._latlng.lat && lng === marker._latlng.lng
    );
    if (newSelectedMarker) {
      this._selectedMarker = newSelectedMarker;
      this._selectedMarker.setStyle(MARKER_DOT_STYLE);
      this.map.flyTo({ lat, lng });
      if (emit) {
        this.selectedPointChange.emit(this._selectedPoint);
      }
    }
  }

  private getDefaultMarkerStyle(point: SimpleMapPoint) {
    return {
      ...DEFAULT_MARKER_STYLE,
      color: point.borderColor || DEFAULT_MARKER_STYLE.color,
      fillColor: point.fillColor || DEFAULT_MARKER_STYLE.fillColor,
    };
  }

  ngOnDestroy(): void {
    this.map?.remove();
  }
}
