import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserProfile } from './userprofile';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserprofileService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/profile/`;
  }

  getProfile() {
    return this.http.get<UserProfile>(this.baseUrl);
  }

  saveProfile(profile: UserProfile) {
    return this.http.put(`${this.baseUrl}`, profile);
  }

  saveLocale(locale: string) {
    return this.http.post(`${this.baseUrl}locale/`, { locale });
  }
}
