import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { GeoRegion } from 'src/app/modules/core/types/geo-area';
import { PlantColor } from 'src/app/modules/core/types/plant-color';
import { BrowserGeoCoordinates } from 'src/app/types/geo-pos';
import { ListResult } from 'src/app/types/list-result';

export class AppActions {
  // Geolocation
  static requestGeoPos = createAction(
    '[App] Request Browser Location'
  );

  // Browser / Web API updates the location
  static geolocationUpdated = createAction(
    '[App] Geolocation information updated',
    props<{ position: BrowserGeoCoordinates }>()
  );

  static geolocationUpdateFail = createAction(
    '[App] Geolocation information update fail',
    props<{ error: { message: string } }>()
  );

  static readHostname = createAction('[App] read hostname');
  static setHostname = createAction('[App] set hostname', props<{ hostname: string}>());
  static setHostnameDirect = createAction('[App] set hostname directly', props<{ hostname: string}>());
  static setSiteSetting = createAction('[App] set site setting', props<{ setting: string}>());

  // today date
  static setTodayDate = createAction('[App] Override Date', props<{ date: Date }>());

  // Color list
  static fetchColorsCache = createAction('[App] Check Colors Cache');
  static fetchColorsCacheHit = createAction('[App] Colors Cache Hit');
  static fetchColors = createAction('[App] Colors Fetch');
  static colorsLoaded = createAction(
    '[App] Colors Loaded',
    props<{ result: ListResult<PlantColor> }>()
  );
  static colorsLoadError = createAction(
    '[App] Colors Load error',
    props<{ error: HttpErrorResponse }>()
  );

  // Locations / GeoAreas
  static fetchLocationsCache = createAction('[App] Check Locations Cache');
  static fetchLocationsCacheHit = createAction('[App] Locations Cache Hit');
  static fetchLocations = createAction('[App] Locations Fetch');
  static locationsLoaded = createAction(
    '[App] Locations Loaded',
    props<{ result: ListResult<GeoRegion> }>()
  );
  static locationsLoadError = createAction(
    '[App] Locations Load error',
    props<{ error: HttpErrorResponse }>()
  );

  // Location selection
  static manualSelectLocation = createAction(
    '[App] Choose Location',
    props<{ locationId: number }>()
  );
  static useAutoLocation = createAction('[App] Use Auto Location');

  // Language / Locale
  static setLocale = createAction(
    '[App] Set Locale',
    props<{ locale: string }>()
  );
  static setLocaleOk = createAction('[App] Set and Save Locale OK');

  static plantNamesLoaded = createAction(
    '[App] Plantnames Loaded',
    props<{ locale: string, data: { latin, local }[] }>()
  );

  static newVersionAvailable = createAction(
    '[App] New App Version is available',
    props<{ newVersionAvailable: boolean }>()
  );

  // Cookie / Tracking state
  static readConsentCookie = createAction('[App] read consent cookie');
  static setConsentState = createAction('[App] set consent flag', props<{ flag: boolean }>());
  static updateConsentStateByUser = createAction('[App] set consent cookie', props<{ flag: boolean }>());
  static updateGaLoadedState = createAction('[App] set ga loaded');

}
