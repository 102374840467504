import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MapDisplayComponent } from 'src/app/modules/maps/map-display/map-display.component';
import { MapPoint } from 'src/app/modules/maps/points.model';
import { AppActions } from 'src/app/state/app/actions';
import { AppSelectors } from 'src/app/state/app/selectors';

@UntilDestroy()
@Component({
  selector: 'app-location',
  templateUrl: './location-page.component.html',
  styleUrls: ['./location-page.component.scss'],
})
export class LocationPageComponent {
  @ViewChild(MapDisplayComponent) private _child: MapDisplayComponent;

  locationInput = new UntypedFormControl();
  geoShapeInput = new UntypedFormControl();
  locations$;
  geoShapeLocations$: Observable<
    Array<{
      coordinates;
      name;
      id;
    }>
  >;
  filteredgeoShapes$;
  geopos$;
  mapPoints$: Observable<MapPoint[]>;
  browserlocation;
  showRegions$;

  mapPoints: MapPoint[] = [];
  reset = false;
  constructor(private store: Store) {
    this.showRegions$ = this.store.select(AppSelectors.appFeatures).pipe(map(features => features.features.shapeMap));
    this.geopos$ = this.store.select(AppSelectors.improvedGeopos);
    this.mapPoints$ = this.geopos$.pipe(map(geopos => [geopos]));
    this.locations$ = this.store.select(AppSelectors.enabledLocations);
    this.geoShapeLocations$ = this.store.select(AppSelectors.locations).pipe(
      // Filter the regions, we want only the ones with geoshape coordinates
      map((locations) =>
        locations.filter(
          (ele) =>
            ele && ele.geoshape && ele.geoshape.coordinates && ele.geoshape.coordinates.length > 0
        )
      ),
      // Flatten the coordinates
      map((locations) =>
        locations.map((ele) => ({
          coordinates: ele.geoshape.coordinates[0],
          name: ele.name,
          id: ele.id,
        }))
      )
    );

    // Combine with the selectbox value and filter
    this.filteredgeoShapes$ = combineLatest([
      this.geoShapeLocations$,
      this.geoShapeInput.valueChanges,
    ]).pipe(
      map(([locations, selectedLocation]) => locations.filter((loc) => loc.id === selectedLocation)),
      map(filtered => filtered || [])
    );

    this.store
      .select(AppSelectors.userRegion)
      .pipe(
        untilDestroyed(this),
        tap((location) => {
          const newLocationId = location ? location.locationId : null;
          this.locationInput.patchValue(newLocationId, {
            emitEvent: false,
          });
        })
      )
      .subscribe();

    this.locationInput.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((selectedLoaction) => {
          this.store.dispatch(AppActions.manualSelectLocation({ locationId: selectedLoaction }));
        })
      )
      .subscribe();
  }

  resetMap() {
    this.store.dispatch(AppActions.requestGeoPos());
    this._child.resetMap();
  }

  locationChange(e, locations) {
    const selected = locations.find((i) => i.id === e);
    let cords = [0, 0];
    if (selected.geoshape.coordinates !== undefined && selected.geoshape.coordinates.length > 0) {
      cords = selected.geoshape.coordinates[0][0];
    }

    const position = {
      coords: {
        latitude: cords[0],
        longitude: cords[1],
        accuracy: 1,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        speed: null,
      },
      timestamp: Math.floor(Date.now() / 1000),
    };
    // this.store.dispatch(AppActions.geolocationUpdated({position}));
  }
}
