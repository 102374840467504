import { createSelector } from "@ngrx/store";
import { PlantsSelectors } from "../plants/selectors";
import { orderBy } from 'lodash-es';
import { ReportSelectors } from "./report.selectors";
import { sub, isAfter } from 'date-fns';
import { AppSelectors } from "../app/selectors";
import distance from '@turf/distance';
import { PlantSchemaLocalized } from "src/app/modules/plants/types";

export interface RankedReportablePlant extends PlantSchemaLocalized {
  weight: number,
  isClose: boolean,
}

export class ReportNowSelectors {

    // this will get plants of the region, blooming now (or soon to start, or just ended - use thie numeric fields)
    // should we make two lists, starting + ending ?
    // and then, prioritiese: what the user just reported .... as spot or reports
    // and then, even higher - when it's at the location (another list ? )
    // BUT: when outside of blooming range ( far outside, then maybe not ? )
    // at list this would order the localised plants ...
    // NOTE: should we make a whole "report now" dashboard, with these lists ?
    // (then finally move the stats away from that dash ?)

    static plantlist = createSelector(PlantsSelectors.extendedPlantsLimitedByRegion, (plants) => {
      const reportable = plants
        .map(plant => ({
          ...plant,
          weight: calcWeight(plant)
        }));
      return reportable;
    });

    // Spots that are recent (have their max date recently)
    static recentSpots = createSelector(ReportSelectors.getSpotsWithPlants, (spots) => {
      const limitDate = sub(new Date(), { months: 3 });
      return spots
        .filter(spot => !!spot.reportedPlant)
        .filter(spot => isAfter(new Date(spot.maxDate), limitDate));
    });

    // Recent spots, with added "distance" measure
    static spotsWithDistance = createSelector(ReportNowSelectors.recentSpots, AppSelectors.improvedGeopos, (spots, pos) => {
      const curloc = pos ? [pos.latitude, pos.longitude] : null;
      return spots.map(spot => ({
        ...spot,
        distance: curloc ? distance(curloc, [spot.lat, spot.lon], { units: 'kilometers' }) : 9999
      }))
    });

    static rankedPlantlist = createSelector(ReportNowSelectors.plantlist, ReportNowSelectors.spotsWithDistance, (plants, spots) => {
      const plantsFromRecentSpotsIds = spots.map(spot => spot.reportedPlant.id);
      const plantsFromCloseSpotsIds = spots.filter(spot => spot.distance < 0.2).map(spot => spot.reportedPlant.id);

      const rankedPlants: RankedReportablePlant[] = plants.map(plant => ({
          ...plant,
          isClose: plantsFromCloseSpotsIds.indexOf(plant.id) !== -1,
          weight: plant.weight
            + (plantsFromRecentSpotsIds.indexOf(plant.id) !== -1 ? RATING_BASE_WEIGHT : 0)
            + (plantsFromCloseSpotsIds.indexOf(plant.id) !== -1 ? RATING_BASE_WEIGHT : 0)
        }))
        .filter((plant) => plant.weight > RATING_BASE_WEIGHT)

      return orderBy(rankedPlants, ['weight', 'id'], ['desc', 'asc']).slice(0, 10);

    });
}

const RATING_BASE_WEIGHT = 7;

function calcWeight(plant) {
  return (plant.bloomingNow ? RATING_BASE_WEIGHT : 0)
    + (Math.abs(plant.daysToBlooming) < 7 ? RATING_BASE_WEIGHT : 0)
    + (Math.abs(plant.daysToBloomingEnd) < 7 ? RATING_BASE_WEIGHT : 0)
    + plant.prevalence;
}