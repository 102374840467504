import { Component, Inject } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';
import { AuthSelectors } from 'src/app/state/auth/auth.selectors';
import { AppSelectors } from 'src/app/state/app/selectors';
import { Observable } from 'rxjs';
import { AppFeatures } from '../../types/app-features';
import { PlantsSelectors } from 'src/app/state/plants/selectors';
import { PlantsActions } from 'src/app/state/plants/actions';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  user$;
  appFeatures$: Observable<AppFeatures>;
  filteredPlants$: Observable<any>;

  search: string;
  searchActive = false;
  expanded = false;

  constructor(@Inject(WINDOW) private window, private store: Store) {
    this.user$ = this.store.select(AuthSelectors.user);
    this.appFeatures$ = this.store.select(AppSelectors.appFeatures);
    this.filteredPlants$ = this.store.select(PlantsSelectors.filteredPlants);
  }

  titleClicked() {
    this.toggle();
  }

  navigateAction() {
    this.hide();
  }

  toggle() {
    this.setExpand(!this.expanded);
  }

  hide() {
    this.setExpand(false);
  }

  setExpand(expanded) {
    this.expanded = expanded;
    this.window.document.body.style.overflow = expanded ? 'hidden' : 'auto';
    if (!expanded) {
      this.searchActive = false;
      this.search = '';
    }
  }

  searchChanged(search: string) {
    this.search = search;
    this.setExpand(this.search?.length > 0);
    this.store.dispatch(PlantsActions.filterPlants({ filterParams: { query: this.search } }))
  }
}
