import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeaturesService } from '../../features.service';
import { User } from 'src/app/modules/auth/user';
import { AppFeatures } from '../../types/app-features';

@Component({
  selector: 'app-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss']
})
export class MenuPanelComponent {
  @Input() user: User;
  @Input() features: AppFeatures;
  @Output() routeClick = new EventEmitter<null>();

  navClick() {
    this.routeClick.emit();
  }

}
