<div class="page-inner main">
  <h1 translate="PAGES.TITLES.LOCATION"></h1>
  <ng-container *ngIf="geopos$ | async as geopos">
    <mat-form-field *ngIf="locations$ | async as locations">
      <mat-label translate="SHARED.LOCATION"></mat-label>
      <mat-select
        [formControl]="locationInput"
        (selectionChange)="locationChange($event.value, locations)"
      >
        <mat-option *ngFor="let location of locations" [value]="location.id">{{
          location.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <p>
      <span>{{ geopos.latitude.toFixed(4) + ',' + geopos.longitude.toFixed(4) }}</span>
    </p>
    <app-map-display [mapPoints]="mapPoints$ | async"></app-map-display>
    <div class="mb-4">
      <button mat-raised-button color="accent" (click)="resetMap()">
        <mat-label>
          {{ 'REPORT.BUTTONS.RESET_LOCATION' | translate }}
        </mat-label>
      </button>
    </div>
  </ng-container>

  <!-- 2nd map instance -->
  <ng-container *ngIf="showRegions$ | async">
    <ng-container *ngIf="geoShapeLocations$ | async as regions">
      <mat-form-field>
        <mat-label translate="SHARED.REGION"></mat-label>
        <mat-select [formControl]="geoShapeInput">
          <mat-option *ngFor="let region of regions" [value]="region.id">{{
            region.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <app-map-shapes [mapPoints]="filteredgeoShapes$ | async"></app-map-shapes>
    </ng-container>
  </ng-container>

  <!-- No geoposition access given -->
  <ng-container *ngIf="!geopos$">
    <h3>{{ 'LOCATION.TURN_ON_LOCATION' | translate }}</h3>
  </ng-container>
</div>
