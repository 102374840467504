import { createReducer, on } from '@ngrx/store';
import {
  createErrorNetworkState,
  createLoadingNetworkState,
  createQuietNetworkState,
} from '../../modules/utils/helpers/network-entities';
import { PlantsActions } from './actions';
import { PlantlistGroupSetting } from '../../modules/plants/helpers/grouping/types';
import { SortVariant } from '../../modules/plants/helpers/sorting/sort-variant';
import { PlantDetailsSchema, PlantFilters, PlantSchema } from '../../modules/plants/types';
import { LoadingState } from '../../modules/utils/types';
import { ViewSetting, viewSettingConfig } from '../../modules/plants/helpers/sorting/types';

export interface PlantsState {
  plants: PlantSchema[];
  plantDetail: PlantDetailsSchema;

  plantListLoading: LoadingState;
  plantListCacheFlag: boolean;
  plantDetailLoading: LoadingState;

  filters: Partial<PlantFilters>;

  groupSetting: PlantlistGroupSetting;

  // TODO: fix type - some mismatch .
  //       seems the reducer sets something else than in state type
  //       and initial state
  sortSetting: any; // Partial<PlantSortSetting>;

  viewSetting: ViewSetting;
}

export const initialPlantsState: PlantsState = {
  plants: [],
  plantDetail: null,
  plantListLoading: createQuietNetworkState(),
  plantListCacheFlag: false,
  plantDetailLoading: createQuietNetworkState(),
  filters: {
    query: '',
    color: '',
  },
  groupSetting: PlantlistGroupSetting.bloomingStart,
  sortSetting: {
    key: SortVariant.name,
    direction: 'asc',
  },
  viewSetting: ViewSetting.blooming,
};

export const PlantsReducer = createReducer(
  initialPlantsState,
  on(PlantsActions.fetchPlants, (state) => ({
    ...state,
    plantListLoading: createLoadingNetworkState(),
    plantListCacheFlag: false,
  })),
  on(PlantsActions.plantsLoaded, (state, action) => ({
    ...state,
    plants: action.result.results,
    plantListLoading: createQuietNetworkState(),
    plantListCacheFlag: true,
  })),
  on(PlantsActions.plantsLoadError, (state, action) => ({
    ...state,
    // plantsNetworkEntity: createErrorNetworkEntity<NetworkListEntity<PlantSchema>>(action.error),
    plantListLoading: createErrorNetworkState(action.error),
  })),

  on(PlantsActions.fetchPlantDetails, (state) => ({
    ...state,
    plantDetailLoading: createLoadingNetworkState(),
  })),
  on(PlantsActions.plantDetailsLoaded, (state, action) => ({
    ...state,
    plantDetail: action.plant,
    plantDetailLoading: createQuietNetworkState(),
  })),
  on(PlantsActions.plantDetailsLoadError, (state, action) => ({
    ...state,
    // plantDetails: createErrorNetworkEntity(action.error),
    plantDetailLoading: createErrorNetworkState(action.error),
  })),
  on(PlantsActions.filterPlants, (state, action) => ({
    ...state,
    filters: action.filterParams,
  })),

  // on(PlantsActions.plantGroupingSettingChange, (state: PlantsState, action) => ({
  //   ...state,
  //   groupSetting: action.setting,

  //   // Set a linked / default sorting for the group, or keep the sorting,
  //   // if none available
  //   sortSetting: GroupDefaultSorting.getDefault(action.setting) || state.sortSetting,

  //   // Clear query string on grouping change
  //   filters: { ...state.filters, query: '' } as PlantFilters,
  // })),
  // on(PlantsActions.plantSortingSettingChange, (state, action) => ({
  //   ...state,
  //   sortSetting: action.setting,

  //   // Clear query string on sorting change
  //   filters: { ...state.filters, query: '' } as PlantFilters,
  // })),

  // Set view setting ( and set grouping and sorting at the same time)
  // NOTE: bit of a state duplication - we could also get rid of the grouping/sorting state,
  // and fetch it only at select time
  on(PlantsActions.viewSettingChange, (state, { setting }) => ({
    ...state,
    viewSetting: setting,
    groupSetting: viewSettingConfig[setting].grouping,
    sortSetting: {
      key: viewSettingConfig[setting].sorting,
      direction: 'asc',
    },

    // Clear query string when view changes
    filters: { ...state.filters, query: '' } as PlantFilters,
  }))
);
