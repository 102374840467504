<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p>{{ data.text }}</p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]>{{ 'APP.BUTTONS.REMIND_ME' | translate }}</button>
  <button mat-raised-button color="accent" [mat-dialog-close] (click)="data.action?.()">
    {{ 'APP.BUTTONS.UPDATE' | translate }}
  </button>
</div>
