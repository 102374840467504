import { Component, Input } from '@angular/core';
import { GreeterMessage } from '../greeter-message';

@Component({
  selector: 'app-greeter-card',
  templateUrl: './greeter-card.component.html',
  styleUrls: ['./greeter-card.component.scss']
})
export class GreeterCardComponent {
  @Input() message: GreeterMessage;
  constructor() { }
}
