import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageTitleResolver } from './resolvers/error-page-title.resolver';
import { ErrorPageDescriptionResolver } from './resolvers/error-page-description.resolver';
import { ErrorPageComponent } from './modules/core/error-page/error-page.component';
import { BaseLayoutComponent } from './modules/core/layouts/base-layout/base-layout.component';
import { WelcomePageComponent } from './modules/misc/page/welcome/welcome-page.component';
import { MiscRoutes } from './modules/misc/misc-routing.module';
import { siteSettingRedirectGuard } from './modules/core/site-setting-redirect.guard';
import { DocsComponent } from './modules/misc/page/docs/docs.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [

      // Welcome page (from misc module)
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full',
      },
      {
        path: 'welcome',
        component: WelcomePageComponent,
        data: {
          title: 'WELCOME.TITLE',
          isRoot: true
        },
      },

      // Redirect the old welcome page
      {
        path: 'misc/welcome',
        redirectTo: 'welcome',
        pathMatch: 'full',
      },

      // Entry-redirect page
      {
        path: 'betterb',
        component: WelcomePageComponent,
        canActivate: [siteSettingRedirectGuard],
        data: {
          setting: 'betterb',
        }
      },

      {
        path: 'plants',
        loadChildren: () => import('./modules/plants/plants.module').then((r) => r.PlantsModule),
      },

      // Import and load as children, but non-layz (module already loaded)
      {
        path: 'misc',
        children: MiscRoutes
      },

      {
        path: 'user',
        loadChildren: () => import('./modules/user/user.module').then((r) => r.UserModule),
      },
      {
        path: 'suggestions',
        loadChildren: () => import('./modules/suggestions/suggestions.module').then((r => r.SuggestionsModule)),
      },
      {
        path: 'doc',
        component: DocsComponent,
        data: {
          title: 'PAGES.TITLES.HELP',
        }
      }
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    resolve: {
      title: ErrorPageTitleResolver,
      description: ErrorPageDescriptionResolver,
    },
  },
  {
    path: '**',
    component: ErrorPageComponent,
    data: {
      title: 'ERROR_RESPONSE.404.TITLE',
      description: 'ERROR_RESPONSE.404.DESCRIPTION',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      // 'enabled' does not seem to fix the overview scroll position
      // 'top' works at least for detail page
      scrollPositionRestoration: 'top'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
