import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';
import { AppActions } from 'src/app/state/app/actions';
import { AppSelectors } from 'src/app/state/app/selectors';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent {
  cookieStatus$;
  constructor(private store: Store) {
    this.cookieStatus$ = combineLatest([
      store.select(AppSelectors.gaApplicable),
      store.select(AppSelectors.consentCookieFlag).pipe(map((flag) => flag === null)),
    ]).pipe(map(([applicable, cookieMissing]) => !!(applicable && cookieMissing)));
  }

  accept(status) {
    this.store.dispatch(AppActions.updateConsentStateByUser({ flag: status }));
  }
}
