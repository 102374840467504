<div class="page-inner main">
    <h1>{{ 'LOCATION.HEADER' | translate }}</h1>
    <section>
        <p>{{ 'LOCATION.INTRO' | translate }}</p>
        <p>{{ 'LOCATION.USAGE' | translate }}</p>
        <p>
            <span *ngIf="geopos$ | async as geopos; else noLoc"><mat-icon color="primary" [inline]="true">check</mat-icon>{{
                geopos.latitude.toFixed(4) + ',' + geopos.longitude.toFixed(4)
                }}</span>
            <ng-template #noLoc><mat-icon [inline]="true">error</mat-icon>{{ 'LOCATION.NO_ACCESS' | translate}}</ng-template>
            <span *ngIf="geoposStatus$ | async as geoposStatus" [title]="geoposStatus">
                Status: {{ geoposStatus }}</span>
        </p>
        <a (click)="requestLocation()" mat-raised-button translate="LOCATION.REQUEST_LOCATION"></a>
    </section>
    <section>
        <h3 translate="LOCATION.HELP_HEADER"></h3>
        <p>{{ 'LOCATION.HELP_DEVICE' | translate }}</p>
        <a routerLink="/" mat-raised-button translate="PAGES.TITLES.HOME"></a>
    </section>
</div>