<ng-container>
  <div class="asGraph">
    <svg viewBox="0 0 124 28" xmlns="http://www.w3.org/2000/svg">
      <rect
        *ngFor="let drawP of drawPeriods"
        [attr.x]="drawP[0]"
        [attr.y]="boxY"
        [attr.width]="drawP[1]"
        [attr.height]="boxHeight"
        style="fill: grey"
        rx="2"
      />

      <line
        *ngFor="let m of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
        [attr.x1]="m * 10"
        [attr.x2]="m * 10"
        [attr.y1]="yStart"
        [attr.y2]="yEnd"
        stroke="black"
        stroke-width="0.2"
      />

      <circle *ngFor="let p of drawPoints" [attr.cx]="p.x" [attr.cy]="p.y" [attr.class]="p.class" r="2"/>
      <rect *ngFor="let p of drawRects" [attr.x]="p.x" [attr.y]="p.y" [attr.class]="p.class" width="1.5" height="2.5"/>

      <text
        class="monthticks"
        [attr.x]="m.x"
        [attr.y]="textY"
        *ngFor="let m of monthTicks"
      >
        {{ m.label }}
      </text>
    </svg>
  </div>
  <div class="asText" *ngIf="period">
    {{ period.bloomingStartDate | date : 'd.MMMM'}} - {{ period.bloomingEndDate | date : 'd.MMMM'}}
  </div>
</ng-container>
