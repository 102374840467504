import { Component } from '@angular/core';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent {
  downloads = [
    {
      url: '/assets/files/Biplantekalenderen_Indstik TfB2_2023.pdf',
      title: 'DOWNLOADS.CALENDAR.TITLE',
      description: 'DOWNLOADS.CALENDAR.DESCRIPTION',
    },
    {
      url: '/assets/files/Guide til pollenfarver.pdf',
      title: 'DOWNLOADS.GUIDE.TITLE',
      description: 'DOWNLOADS.GUIDE.DESCRIPTION',
    }
  ]
}
