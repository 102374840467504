<div class="desktop-menu">
  <nav>
  <a (click)="hide()" routerLink="/plants/list" routerLinkActive="active">{{ 'PAGES.TITLES.CATALOGUE' | translate }}</a>
  <a *ngIf="features.modes.pollen" (click)="hide()" routerLink="/plants/find" routerLinkActive="active">{{ 'PAGES.TITLES.POLLENID' | translate }}</a>
  <a (click)="hide()" routerLink="/plants/report" routerLinkActive="active">{{ 'PAGES.TITLES.REPORT' | translate }}</a>
  <a *ngIf="features.modes.pollen" (click)="hide()" routerLink="/misc/downloads" routerLinkActive="active">{{ 'PAGES.TITLES.DOWNLOADS' | translate }}</a>
  <a (click)="hide()" routerLink="/misc/about" routerLinkActive="active">{{ 'PAGES.TITLES.ABOUT' | translate }}</a>
  <a (click)="hide()" routerLink="/user/user">{{ 'MENU.SETTINGS' | translate }}</a>
  </nav>
</div>
