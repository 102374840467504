<div class="logos" [ngClass]="{'inFooter' : forFooter}">
    <img src="/assets/images/promilleafgiftsfonden-logo-480.png" class="large">
    <img src="/assets/images/logos/logo-eu.png" class="quitedark" *ngIf="hostname === 'beeplants.eu'">
    <img src="/assets/images/logos/logo-uk.png" class="quitedark" *ngIf="hostname === 'beeplants.eu'">
    <img src="/assets/images/logos/logo-ch.png" class="quitedark" *ngIf="hostname === 'beeplants.eu'">
    <img src="/assets/images/logos/logo-biavl.png">
    <img src="/assets/images/logos/logo-alveus.png" *ngIf="hostname === 'beeplants.eu'">
    <div *ngIf="hostname === 'beeplants.eu'" class="supporter-text">
        This work was supported by the Better-B project, which has received funding from the European Union, the Swiss State Secretariat for Education, Research and Innovation (SERI), and UK Research and Innovation (UKRI) under the UK government's Horizon Europe funding guarantee (grant number 10068544)
    </div>
</div>