import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-supporters',
  templateUrl: './supporters.component.html',
  styleUrls: ['./supporters.component.scss']
})
export class SupportersComponent {
  @Input() forFooter = true;
  @Input() hostname = '';
}
