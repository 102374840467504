import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { AppSelectors } from 'src/app/state/app/selectors';

@Component({
  selector: 'app-about',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent {
  showSupporters$;
  hostname$;
  constructor(store: Store) {
    this.showSupporters$ = store.select(AppSelectors.appFeatures).pipe(map(feat => feat.features.showSupporters || feat.betaTester));
    this.hostname$ = store.select(AppSelectors.appFeatures).pipe(map(feat => feat.hostname));
   }
}