import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './components/footer/footer.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ToolbarTitleComponent } from './components/toolbar-title/toolbar-title.component';
import { RouterModule } from '@angular/router';
import { OfflineNotifierComponent } from './components/offline-notifier/offline-notifier.component';
import { SharedModule } from '../shared/shared.module';
import { ErrorPageComponent } from './error-page/error-page.component';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuPanelComponent } from './components/menu-panel/menu-panel.component';
import { AppVersionService } from 'src/app/modules/core/app-version.service';
import { AlertDialogComponent } from './components/alert/alert.component';
import { AlertService } from './alert.service';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { MenuPanelContainerComponent } from './components/menu-panel-container/menu-panel-container.component';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';

@NgModule({
  declarations: [
    ErrorPageComponent,
    FooterComponent,
    OfflineNotifierComponent,
    BaseLayoutComponent,
    ToolbarComponent,
    ToolbarTitleComponent,
    MenuComponent,
    MenuPanelComponent,
    AlertDialogComponent,
    GlobalSearchComponent,
    MenuPanelContainerComponent,
    CookieConsentComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    TranslateModule.forChild({ extend: true }),
    SharedModule
  ],
  exports: [
    ErrorPageComponent,
    FooterComponent,
    OfflineNotifierComponent,
    ToolbarComponent,
    ToolbarTitleComponent,
    AlertDialogComponent,
    GlobalSearchComponent,
    CookieConsentComponent,
  ],
  providers: [
    AlertService
  ]
})
export class CoreModule {
  constructor(private appVersionService: AppVersionService) { }
}
