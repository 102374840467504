import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";

export interface AlertDialogData {
  title: string;
  text: string;
  action: () => void;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertDialogComponent {

  static getSettings(options: AlertDialogData): MatDialogConfig {
    const data = { ...options };
    return {
      panelClass: ['app-dialog'],
      data,
      maxHeight: '75%',
      width: '95%',
      maxWidth: '720px',
    }
  }

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
  ) { }
}
