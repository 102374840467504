import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertDialogComponent } from "./components/alert/alert.component";

@Injectable({ providedIn: 'root' })
export class AlertService {
  constructor(private dialog: MatDialog) { }

  openWarning(title: string, text: string, action: () => void) {
    this.dialog.open(AlertDialogComponent, AlertDialogComponent.getSettings({
      title, text, action
    }))
  }
}
