import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AboutPageComponent } from './page/about/about-page.component';
import { ImprintPageComponent } from './page/imprint/imprint-page.component';
import { StyleguideComponent } from './page/styleguide/styleguide.component';
import { LocationPageComponent } from './page/location/location-page.component';
import { PrivacyComponent } from './page/privacy/privacy.component';
import { GeopositionHelpComponent } from './page/geoposition-help/geoposition-help.component';
import { DownloadsComponent } from './page/downloads/downloads.component';
import { AdminComponent } from './page/admin/admin.component';
import { canActivateStaff } from '../core/can-activate-staff';
import { CanActivateAuth } from '../core/can-activate-auth';

// NOTE: some routes for pages in the misc module are defined in app-routing !
export const MiscRoutes = [
  {
    path: 'about',
    component: AboutPageComponent,
    data: {
      title: 'PAGES.TITLES.ABOUT',
    },
  },
  {
    path: 'admin',
    component: AdminComponent,
    data: {
      title: 'Admin',
    },
    canActivate: [CanActivateAuth,canActivateStaff],
  },
  {
    path: 'downloads',
    component: DownloadsComponent,
    data: {
      title: 'PAGES.TITLES.DOWNLOADS',
    },
  },
  {
    path: 'imprint',
    component: ImprintPageComponent,
    data: {
      title: 'PAGES.TITLES.IMPRINT',
    },
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {
      title: 'PAGES.TITLES.PRIVACY',
    },
  },
  {
    path: 'location-access',
    component: GeopositionHelpComponent,
    data: {
      title: 'PAGES.TITLES.LOCATION_HELP',
    },
  },

  // Internal
  {
    path: 'location',
    component: LocationPageComponent,
    data: {
      title: 'PAGES.TITLES.ABOUT',
    },
  },
  {
    path: 'styleguide',
    component: StyleguideComponent,
    data: {
      title: 'Styleguide',
    },
  },
] as Route[];

@NgModule({
  imports: [
    RouterModule.forChild([]),
  ],
  exports: [RouterModule],
})
export class MiscRoutingModule {
}
