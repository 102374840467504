import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { AppSelectors } from 'src/app/state/app/selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  curYear = new Date().getFullYear();
  public version: string = environment.version;
  isStaff$;
  showSupporters$;
  hostname$;
  constructor(store: Store) {
    this.isStaff$ = store.select(AppSelectors.appFeatures).pipe(map(feat => feat.features.staff));
    this.showSupporters$ = store.select(AppSelectors.appFeatures).pipe(map(feat => feat.features.showSupporters || feat.betaTester));
    this.hostname$ = store.select(AppSelectors.appFeatures).pipe(map(feat => feat.hostname));
  }

}
