<div class="banner-pos" *ngIf="this.cookieStatus$ | async as status">
    <div *ngIf="status">
        <div class="banner">
            <div class="banner-inner">
                <h4 translate="APP.COOKIES.HEADER"></h4>
                <p>{{'APP.COOKIES.TEXT' | translate}} <a [routerLink]="['/misc/privacy']"
                        translate="APP.COOKIES.READ_MORE"></a></p>
                <button mat-raised-button color="accent" (click)="accept(true)"
                    translate="APP.COOKIES.ACCEPT_ALL"></button>
                <button mat-button (click)="accept(false)" translate="APP.COOKIES.ACCEPT_REQUIRED_ONLY"></button>
            </div>
        </div>
    </div>
</div>