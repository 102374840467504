<div class="search-container" [class.active]="searchIsVisible">
  <input
    #searchInput
    class="search-input"
    type="text"
    name="search"
    placeholder="Search..."
    autocomplete="off"
    [formControl]="search"
    [class.active]="searchIsVisible"
    (keydown)="searchKeydown($event)"
  />
  <button mat-icon-button [class.searching]="searchIsVisible" (click)="toggleSearch()">
    <mat-icon>{{ searchIsVisible ? 'close' : 'search' }}</mat-icon>
  </button>
</div>
