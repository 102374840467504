import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { NavigationActions } from './actions';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NavigationStack } from './reducers';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AppSelectors } from '../app/selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class NavigationEffects {
  onNavigation$ = createEffect(() =>
    this.actions.pipe(
      ofType(routerNavigatedAction),
      map(({ payload }) => (payload.routerState as unknown) as NavigationStack),
      mergeMap((navigationStack: NavigationStack) => {
        return this.translate.stream(navigationStack.title).pipe(
          tap((translated: string) => this.titleService.setTitle(translated)),
          map(() => {
            if (navigationStack.isRoot) {
              return NavigationActions.navigationStackReset();
            }
            return NavigationActions.navigation(navigationStack);
          })
        );
      })
    )
  );

  trackingSend$ = createEffect(() =>
    this.actions.pipe(
      ofType(routerNavigatedAction),
      withLatestFrom(this.store.select(AppSelectors.gaEnabled)),
      tap(([{ payload }, isEnabled]) => {
        const url = payload?.event?.url;
        // console.log("Navigate", url);
        // console.log("Enabled", isEnabled);
        if (isEnabled && url) {
          this.ga.pageView(url);
        }
      })
    ), {dispatch: false}
  );

  constructor(
    private actions: Actions,
    private titleService: Title,
    private translate: TranslateService,
    private ga: GoogleAnalyticsService,
    private store: Store,
  ) {}
}
