import { createReducer, on } from "@ngrx/store";
import { PlantSuggestionSchema } from "src/app/modules/suggestions/types/suggestions";
import { SuggestionsActions } from "./actions";

export interface SuggestionsState {
  suggestions: PlantSuggestionSchema[];
}

export const initialSuggestionsState: SuggestionsState = {
  suggestions: []
};

export const SuggestionsReducer = createReducer(
  initialSuggestionsState,
  on(SuggestionsActions.fetchSuggestionsSuccess, (state, { result }) => ({
    ...state,
    suggestions: result.results
  })),
)
