<div class="container">
  <label *ngIf="withLabel">
    {{ 'SHARED.LANGUAGE.LANGUAGE' | translate }}
  </label>

  <div>
    <mat-icon [inline]="true">language</mat-icon>
    <mat-select [formControl]="selectControl" [class.withlabel]="withLabel">
      <mat-option *ngFor="let code of langs" [value]="code.code" (click)="setLanguage(code.code)">
        {{ code.translateKey | translate }}
      </mat-option>
    </mat-select>
  </div>
</div>