import { FeatureMap } from "src/environments/feature-map";

export enum SiteModes {
    betterb="betterb",
    pollen="pollen"
}

export type ModesMap = {[key in keyof typeof SiteModes]?: boolean};

export interface AppFeatures {
    modes: ModesMap;
    largeFab: boolean;
    features: FeatureMap;
    hostname: string;
    siteSetting: string;
    loggedIn: boolean;
    betaTester: boolean;
}

export const appFeaturesDefault = {
    modes: [],
    largeFab: true,
    features: {} as FeatureMap,
    hostname: '',
    siteSetting: '',
    loggedIn: false,
    betaTester: false,
} as AppFeatures;

export const defaultModes = {
    'betterb': {
        [SiteModes.betterb]: true,
        [SiteModes.pollen]: false,
    },
    'pollen': {
        [SiteModes.betterb]: false,
        [SiteModes.pollen]: true,
    }
}