import { AfterViewInit, Component, OnInit, Input, OnChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import * as L from 'leaflet';
import { MapPoint } from '../points.model';

@Component({
  selector: 'app-map-shapes',
  templateUrl: './map-shapes.component.html',
  styleUrls: ['./map-shapes.component.scss'],
})
export class MapShapesComponent implements AfterViewInit, OnChanges, OnDestroy {
  _mapPoints: MapPoint[] = [];
  @Input() set mapPoints(points: MapPoint[]) {
    this._mapPoints = points || [];
  }
  get mapPoints(): MapPoint[] {
    return this._mapPoints;
  }
  @Input() allowAdd = false;
  @Input() allowDrag = false;
  @Output() savePoint = new EventEmitter<MapPoint>();
  @Output() addPoint = new EventEmitter<MapPoint>();

  private map: any;
  private markers: any[] = [];
  locations = [];
  geojsonLayer;
  constructor() {}

  private initMap(): void {
    this.map = L.map('mapInput2', {
      // TODO: from attribute
      scrollWheelZoom: false,
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 16,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);

  }

  private creategeoJson(markers) {
    this.locations = [];
    markers.forEach((element, i) => {
      const location = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [element.coordinates]
        }
      };
      this.locations.push(location);
    });
  }

  private addPointsOnMap(markers): void {
    this.creategeoJson(markers);
    this.geojsonLayer = L.geoJson(this.locations);
    this.geojsonLayer.addTo(this.map);
  }

  private filterPointsOnMap(markers): void {
    this.map.removeLayer(this.geojsonLayer);
    this.addPointsOnMap(markers);
  }

  private zoomCenterizedMap(): void {
    if (!this.markers || this.markers.length === 0) {
      this.map.setView([0, 0], 2);
      return;
    }
    const group = new L.featureGroup(this.markers);
    // Fit the markers, but use a max-zoom level (no closer)
    this.map.fitBounds(group.getBounds(), { maxZoom: 14.0 });
  }

  ngOnChanges(): void {
    if (this.map && this._mapPoints) {
      this.filterPointsOnMap(this._mapPoints);
    }
  }

  ngAfterViewInit(): void {
    this.initMap();
    if (this.map && this._mapPoints) {
      this.addPointsOnMap(this._mapPoints);
    }
    this.zoomCenterizedMap();
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }
}
