import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from '../../../helpers';

const AVAILABLE_LOCALES = LANGUAGES.map((lang) => lang.code);
const DEFAULT_LANGUAGE = 'da';  // TODO: from store, depending on hostname !

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  localStorage;
  constructor(@Inject(WINDOW) private window, private translate: TranslateService) {
    this.localStorage = window.localStorage;
  }

  getInitalLanguage() {
    const pref = this.readPref();
    if (pref && this.available(pref)) {
      return pref;
    }
    const browserLang = this.translate.getBrowserLang();
    if (browserLang && this.available(browserLang)) {
      return browserLang;
    }
    // TODO: quickfix. Ideally, this would come from the store / selectors to have it all in one place
    const defLang = (this.window.location?.hostname || "").startsWith("bee") ? "en" : DEFAULT_LANGUAGE;

    console.log(`${this.window.location?.hostname} ${defLang}`);
    return defLang;
  }

  updateLanguage(lang) {
    this.setPref(lang);
  }

  private available(lang) {
    return AVAILABLE_LOCALES.indexOf(lang) !== -1;
  }

  private readPref() {
    return this.localStorage && this.localStorage.getItem('preferred_locale');
  }

  private setPref(lang) {
    if (this.localStorage) {
      this.localStorage.setItem('preferred_locale', lang);
    }
  }
}
