import { HttpClient } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { take, tap } from 'rxjs';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocsComponent {
  data;
  constructor(http: HttpClient) {
    http
      .get('/assets/help/main-en.1.md', {responseType: 'text'})
      .pipe(
        take(1),
        tap((data: string) => (this.data = data))
      )
      .subscribe();
  }
}
