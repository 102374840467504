import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapDisplayComponent } from './map-display/map-display.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MapShapesComponent } from './map-shapes/map-shapes.component';
import { SimpleMapComponent } from './simple-map/simple-map.component';

@NgModule({
  declarations: [MapDisplayComponent, MapShapesComponent, SimpleMapComponent],
  exports: [MapDisplayComponent, MapShapesComponent, SimpleMapComponent],
  imports: [CommonModule, FormsModule, SharedModule],
})
export class MapsModule {}
