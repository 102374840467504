import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { PlantSuggestionSchema } from "../types/suggestions";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: 'root' })
export class PlantsSuggestionsService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiUrl}/suggestions/`;
  }

  getSuggestions() {
    return this.http.get<{ results: Array<any> }>(this.baseUrl);
  }

  addSuggestion(suggestion: PlantSuggestionSchema): Observable<PlantSuggestionSchema> {
    return this.http.post(`${this.baseUrl}`, suggestion).pipe(map(response => response as PlantSuggestionSchema));
  }

  deleteSuggestion(suggestionId: number) {
    return this.http.delete(`${this.baseUrl}${suggestionId}`);
  }
}
