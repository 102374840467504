import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppActions } from "src/app/state/app/actions";

export const siteSettingRedirectGuard: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const store = inject(Store);
    const router = inject(Router);
    const setting = route.data?.setting;
    if (setting) {
      store.dispatch(AppActions.setSiteSetting({ setting }));
    }
    return router.createUrlTree(['']);
  };