import { AfterViewInit, Component, OnInit, Input, OnChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as L from 'leaflet';
import { AppActions } from 'src/app/state/app/actions';
import { MapPoint } from '../points.model';

@Component({
  selector: 'app-map-display',
  templateUrl: './map-display.component.html',
  styleUrls: ['./map-display.component.scss'],
})
export class MapDisplayComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() mapPoints: MapPoint[];
  @Input() allowAdd = false;
  @Input() allowDrag = false;
  @Output() savePoint = new EventEmitter<MapPoint>();
  @Output() addPoint = new EventEmitter<MapPoint>();

  private map: any;
  private markers: any[] = [];
  private target: any;
  private greenIcon: any;

  constructor(private store: Store) {}

  private initMap(): void {
    this.map = L.map('mapInput', {
      // TODO: from attribute
      scrollWheelZoom: false,
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 16,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);

    if (this.allowAdd) {
      // Drag and drop adding marker
      this.target = document.getElementById('mapInput');
      this.target.ondragover = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
      };

      // Only for adding new markers
      // TODO make this optional via inputi
      this.target.ondrop = (e) => {
        e.preventDefault();
        const coordinates = this.map.containerPointToLatLng(L.point([e.clientX, e.clientY]));

        const marker = L.marker(coordinates, {
          icon: this.greenIcon,
          draggable: true,
          id: this.markers.length + 1,
          desc: 'New Marker',
        }).addTo(this.map);
        marker.bindPopup(`<b>New Marker</b>`);

        this.markerDragend(marker);
        const point: MapPoint = {
          id: this.markers.length + 1,
          description: 'New Marker',
          latitude: coordinates.lat,
          longitude: coordinates.lng,
        };
        this.addPoint.emit(point);
        this.zoomCenterizedMap();
      };
    }
  }

  private addPointsOnMap(markers: MapPoint[]): void {
    this.removePointsFromMap();
    this.greenIcon = L.icon({
      iconUrl: '../../../assets/images/marker.svg',
      // iconUrl: '../../../assets/leaf-green.png',
      // shadowUrl: '../../../assets/leaf-shadow.png',
      iconSize: [24, 24], // size of the icon
      className: 'map-marker',
      // shadowSize: [50, 64], // size of the shadow
      // iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
      // shadowAnchor: [4, 62], // the same for the shadow
      // popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
    });

    markers.map((mark) => {
      const marker = L.marker([mark.latitude, mark.longitude], {
        icon: this.greenIcon,
        draggable: this.allowDrag,
        ...(mark.id && { id: mark.id }), // If there is id
      }).addTo(this.map);
      if (mark.description) {
        marker.bindPopup(`<b>${mark.description}</b>`);
      }

      // TODO: figure out why this needs to be called to get markers displayed
      // This shouldn't be used if marker is not draggable (moved array filling out, so that it's worked)
      if (this.allowDrag) {
        this.markerDragend(marker);
      }

      this.markers = [...this.markers, marker];
    });
  }

  private removePointsFromMap(): void {
    if (this.map && this.markers.length) {
      this.markers.map((item) => {
        this.map.removeLayer(item);
      });
      this.markers = [];
    }
  }

  private markerDragend(marker): void {
    marker.on('dragend', (event) => {
      const newMarker = event.target;
      const position = newMarker.getLatLng();

      newMarker.setLatLng(new L.LatLng(position.lat, position.lng), {
        draggable: this.allowDrag,
      });
      // this.map.panTo(new L.LatLng(position.lat, position.lng));

      this.mapPoints.map((point) => {
        if (newMarker.options.id === point.id) {
          this.savePoint.emit({
            ...point,
            latitude: position.lat,
            longitude: position.lng,
          });
        }
      });
      this.zoomCenterizedMap();
    });
  }

  private zoomCenterizedMap(): void {
    if (!this.markers || this.markers.length === 0) {
      this.map.setView([0, 0], 0);
      return;
    }
    const group = new L.featureGroup(this.markers);
    // Fit the markers, but use a max-zoom level (no closer)
    this.map.fitBounds(group.getBounds(), { maxZoom: 14.0 });
  }

  ngOnChanges(): void {
    if (this.map && this.mapPoints) {
      this.addPointsOnMap(this.mapPoints);
    }

  }

  mapClick(){
    this.map.on('click', (e) => {
      this.removePointsFromMap();
      const marker = L.marker([e.latlng.lat, e.latlng.lng], {
        icon: this.greenIcon,
      }).addTo(this.map);
      this.markers.push(marker);
      const position = {
        coords: {
          latitude: e.latlng.lat, longitude: e.latlng.lng,
          accuracy: 1 , altitude: null, altitudeAccuracy: null,
          heading: null, speed: null
        },
        timestamp : Math.floor(Date.now() / 1000)
      };
      this.store.dispatch(AppActions.geolocationUpdated({position}));
    });
  }

  resetMap() {
    const cords = this.mapPoints[0];
    this.removePointsFromMap();
    const marker = L.marker([cords.latitude, cords.longitude], {
      icon: this.greenIcon,
    }).addTo(this.map);
    this.markers.push(marker);
  }

  ngAfterViewInit(): void {
    this.initMap();
    if (this.map && this.mapPoints) {
      this.addPointsOnMap(this.mapPoints);
    }
    this.zoomCenterizedMap();
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }
}
