import { createAction, props } from '@ngrx/store';
import { PlantDetailsSchema, PlantFilters, PlantSchema } from '../../modules/plants/types';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewSetting } from '../../modules/plants/helpers/sorting/types';
import { ListResult } from 'src/app/types/list-result';

export class PlantsActions {
  static fetchPlantsCache = createAction('[Plants] Check Cache');
  static fetchPlantsCacheHit = createAction('[Plants] Fetch From Cache');
  static fetchPlants = createAction('[Plants] Fetch');
  static plantsLoaded = createAction(
    '[Plants] Loaded',
    props<{ result: ListResult<PlantSchema> }>()
  );
  static plantsLoadError = createAction(
    '[Plants] Load error',
    props<{ error: HttpErrorResponse }>()
  );
  static fetchPlantDetails = createAction(
    '[Plants] Fetch plant details',
    props<{ plantId: number }>()
  );
  static plantDetailsLoaded = createAction(
    '[Plants] Load plant details',
    props<{ plant: PlantDetailsSchema }>()
  );
  static plantDetailsLoadError = createAction(
    '[Plants] Load plant details error',
    props<{ error: HttpErrorResponse }>()
  );
  static filterPlants = createAction('[Plants] filter', props<{ filterParams: PlantFilters }>());

  // static plantGroupingSettingChange = createAction(
  //   '[Plants] Grouping setting changed',
  //   props<{ setting: PlantlistGroupSetting }>()
  // );
  // static plantSortingSettingChange = createAction(
  //   '[Plants] Sorting setting changed',
  //   props<{ setting: PlantSortSetting }>()
  // );
  static viewSettingChange = createAction(
    '[Plants] Change View Setting',
    props<{ setting: ViewSetting }>()
  );
}
