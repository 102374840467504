import { Component, Inject, OnInit } from '@angular/core';
import { AlertService } from '../../../core/alert.service';

@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss'],
})
export class StyleguideComponent {
  constructor(private alertService: AlertService) {}

  typography = [
    "mat-headline-1",
    "mat-headline-2",
    "mat-headline-3",
    "mat-headline-4",
    "mat-h1",
    "mat-h2",
    "mat-h3",
    "mat-h4",
    "mat-h5",
    "mat-h6",
    "mat-body",
    "mat-body-strong",
    "mat-small"
  ]


  showAlert() {
    this.alertService.openWarning('Title', 'Alert message text', () => {});
  }

  produceAnError() {
    const a = {};
    a['something'].somethingElse();
  }
}
