import { createReducer, on } from '@ngrx/store';
import { NavigationActions } from './actions';

export interface NavigationStack {
  isRoot?: boolean;
  isBase?: boolean;
  url: string;
  path: string;
  title: string;
  defaultStack?: boolean;
}

export interface NavigationState {
  navigationStack: NavigationStack[];
  plantId: number;
}

const defaultNavigationStack = [
  {
    url: '',
    title: 'PAGES.TITLES.MAIN',
    path: '/',
    isBase: false,
    defaultStack: true
  },
];

const initialState: NavigationState = {
  navigationStack: defaultNavigationStack,
  plantId: null
};

export const NavigationReducer = createReducer<NavigationState>(
  initialState,
  on(NavigationActions.navigation, (state, action) => {
    // "Hack": query string crashes the logic of the navigationStack
    // For a rough fix, just remove the query string from the path
    action = {
      ...action,
      path: action.path.split('?')[0]
    };

    if (
      state.navigationStack.length > 2 &&
      action.path === state.navigationStack[state.navigationStack.length - 2].path
    ) {
      return {
        ...state,
        navigationStack: [...state.navigationStack.slice(0, state.navigationStack.length - 1)],
      };
    }
    if (state.navigationStack[state.navigationStack.length - 1].path !== action.path) {
      return {
        ...state,
        navigationStack: [...state.navigationStack, { ...action }],
      };
    }
    return { ...state };
  }),

  on(NavigationActions.navigationStackReset, (state) => ({
    ...state,
    navigationStack: defaultNavigationStack,
  })),

  on(NavigationActions.setPlantDetail, (state, {id}) => {
    return ({
    ...state,
    plantId: parseInt(id, 10)
  }); })
);
