import { createSelector } from '@ngrx/store';
import { PlantSchemaLocalized } from 'src/app/modules/plants/types';
import { BloomingData } from 'src/app/modules/report/types/blooming-data';
import { State } from '../reducers';
import { ReportSelectors } from '../report/report.selectors';
import { groupBy, min, orderBy, shuffle } from 'lodash-es';
import { isAfter, sub, format } from 'date-fns';
import { GreeterMessage } from 'src/app/modules/misc/components/greeter-message';
import { ResultSelectors } from '../report/result.selectors';

export const greeterStateSelector = (state: State) => state.greeter;

export class GreeterSelectors {

  /**
   * Build messags from reportable plants and recent (currently: all) reports
   */
  static greeterMessagesPool = createSelector(
    ReportSelectors.reportablePlants,
    ResultSelectors.getBloomingDataForVisLoc,
    (plants, reports) => generateGreeterMessages(plants, reports)
  );

  /**
   * Produce a "static" random order once per app start
   */
  static randomOrder = createSelector(greeterStateSelector,
    (state) => shuffle([...Array(100).keys()])
  )

  /**
   * Shuffle messages via a one-time generated random sequence.
   * (plain shuffle would re-evaluate on every change and produce "instable" list)
   */
  static messages = createSelector(
    GreeterSelectors.greeterMessagesPool,
    GreeterSelectors.randomOrder,
    (messages, order) => orderBy(
      messages.map((m, idx) => ({...m, rand_order: order[idx]})), 'rand_order'
    )
  );

}

// Consider plants as "soon to bloom" when within days to bloom
const DAYS_TO_BLOOMING_RANGE = 14;
const MAX_PER_TYPE = 4;

/**
 * Helper function that creates a unified array of messages for reporting
 * engagement (using and mapping from plants and reports, mainly).
 * @param plants
 * @param reports
 * @returns array of greeter messages
 */
function generateGreeterMessages(
  plants: PlantSchemaLocalized[],
  reports: BloomingData[]
): GreeterMessage[] {
  // Plants that are blooming or will start blooming soon
  // TODO : should we focus on close to start, close to end plants ?
  const soonToBloom = plants.filter(
    (plant) =>
      plant.bloomingNow || plant.daysToBlooming < DAYS_TO_BLOOMING_RANGE
  );

  // Plants with most recent reports
  const limitDate = sub(new Date(), { months: 1});
  const recentReports = reports.filter(report => isAfter(new Date(report.reportDate), limitDate));
  const reportsByPlant = groupBy(recentReports, 'plant');
  const repStats = orderBy(
    Object.keys(reportsByPlant)
      .map((k) => reportsByPlant[k])
      .map((grp) => ({
        // plant + last reported + number of reports
        plant: grp[0].reportedPlant,
        date: min(grp.map((rep) => rep.reportDate)),
        n: grp.length,
      })),
    'date',
    'desc'
  );

  const messagesFromBloming = soonToBloom.map((plant) => ({
    header: plant.name,
    text: 'ENGAGEMENT.BLOOMING_SOON',
    textParams: { plant: plant.name },
    plant: plant,
  }));

  const messagesFromReports = repStats.map((repStat) => ({
    header: repStat.plant.name,
    text: 'ENGAGEMENT.RECENT_REPORTING',
    textParams: { plant: repStat.plant.name, n: repStat.n, date: format(new Date(repStat.date), 'PP') },
    plant: repStat.plant,
  }));

  // Concat and shuffle
  // TODO: do unique by plant ? (not get same plant in message from two different sources)
  const messages = [
    ...messagesFromBloming.slice(0, MAX_PER_TYPE),
    ...messagesFromReports.slice(0, MAX_PER_TYPE),
  ];

  return messages;
}
